import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { isLoggedIn, tester } from "../../services/auth"
import Layout from "../../components/global/layout"
import Container from "../../components/container/container"
import { AuthContext } from "../../context/AuthContext"
import AccountLayout from "../../components/account/account-layout"
import Intro from "../../components/collections/intro"
import LogoutButton from "../../components/logout-button/logout-button"


const Account = () => {
  
  const { contextIsUserLoggedIn, handleContextCheckLoggedIn } =
    useContext(AuthContext)


  useEffect(() => {
    if (!contextIsUserLoggedIn) {
      navigate(`/login`)
    }
  }, [contextIsUserLoggedIn])

   return (
      <Layout>
          <Intro title={"Account"}>
            <LogoutButton secondary />
          </Intro>
          <Container gutters width={"medium"} pb={3}>
            <AccountLayout />
          </Container>
      </Layout>
  )
}
export default Account


