import React from "react"
import Button from "../button/button"
import style from "./account-header.mod.scss"

const AccountHeader = ({ heading, buttonText, buttonMethod }) => {
  return (
    <div className={style.header}>
      <h3>{heading}</h3>
      <Button miniMobile onClick={buttonMethod}>{buttonText}</Button>
    </div>
  )
}

export default AccountHeader
