import React, { useEffect, useState } from "react"
import { getUser } from "../../services/auth"
import { getOrder, getOrders, getOrderStatus } from "../../services/orders"
import Container from "../container/container"
import style from "./orders.mod.scss"

const MyOrders = () => {
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState(false)

  useEffect(() => {
    const user = getUser()

    const getOrdersData = {
      user: user,
    }
    getOrders(getOrdersData).then((res) => {
      setLoading(false)
      setList(res.data.orders)
    })
  }, [])

  if (loading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <Container width={"small"}>
      <div className={style.orders}>
        {!!list.length ? (
          <table>
            <thead>
              <th>Order ID</th>
              <th>Order Date</th>
              <th>Status</th>
              <th>Qty</th>
              <th>Total Price</th>
            </thead>
            <tbody>
              {list.map((order) => (
                <tr>
                  <td>#{order.order_id}</td>
                  <td>{order.created_at}</td>
                  <td
                    className={
                      order.status === "Completed" && style.orders__complete
                    }
                  >
                    <span className={style.orders__status}>{order.status}</span>
                  </td>
                  <td>{order.item_count}</td>
                  <td>${order.total_amount.toFixed(2)} AUD</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No orders found.</p>
        )}
      </div>
    </Container>
  )
}

export default MyOrders
