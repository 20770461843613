import React, { useEffect, useState, useContext } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Container from "../container/container"
import Input from "../form/Input"
import { getUser } from "../../services/auth"
import { updateProfile } from "../../services/profile"
import { AuthContext } from "../../context/AuthContext"
import Button from "../button/button"
import FormHeader from "../form/form-header"
import { toast } from "react-toastify"

const schema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    username: yup.string().required("Username is required"),
  })
  .required()

const UpdateProfileForm = ({ component: Component, location }) => {
  const { contextIsUserLoggedIn, handleContextCheckLoggedIn, handleSyncUser } =
    useContext(AuthContext)

  const user = getUser()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      username: user.username,
    },
  })
  const {
    register,
    handleSubmit,
    setError,
    defaultValues,
    formState: { errors },
  } = methods

  const [generalMsg, setGeneralMsg] = useState("")

  const onSubmit = async (data) => {
    // console.log("onSubmit: ", data)
    data.user = user
    // addAddress(data).then((res) => {

    // })
    updateProfile(data).then((res) => {
      // console.log("updateProfile() Response: ", res)
      if (res.status) {
        // console.log("onSubmit RES", data)
        toast.success(res.message)
        handleSyncUser()
      } else if (res.message) {
        toast.error(res.message)
      }
    })
  }

  return (
    <div>
      <FormHeader
        border
        heading={"My details"}
        text={"Feel free to edit your details so your account is up to date."}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Input
            label={"First Name"}
            name="first_name"
            type="text"
            {...register("first_name")}
            required
            placeholder={"Your First Name"}
            errorMessage={errors.first_name?.message}
            secondary
          />
          <Input
            label={"Last Name"}
            name="last_name"
            type="text"
            {...register("last_name")}
            required
            placeholder={"Your Last Name"}
            errorMessage={errors.last_name?.message}
            secondary
          />
          <Input
            label={"Email"}
            name="email"
            type="text"
            {...register("email")}
            required
            placeholder={"Your email"}
            errorMessage={errors.email?.message}
            secondary
          />
          <Input
            label={"Username"}
            name="username"
            required
            {...register("username")}
            placeholder={"Your Username"}
            errorMessage={errors.username?.message}
            secondary
          />
          <Container pt={1}>
            <Button type="submit">Save Profile</Button>
          </Container>
        </form>
      </FormProvider>
    </div>
  )
}
export default UpdateProfileForm
