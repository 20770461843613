import React from "react"
import Button from "../button/button"
import { logout } from "../../services/auth"

const LogoutButton = ({  children,
  ...props}) => {

  const handleLogout = () => {
    logout()
  }

  return (
    <Button {...props} onClick={handleLogout}>
      {"Logout"}
    </Button>
  )
}

export default LogoutButton
