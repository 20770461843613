import React, { useState, useContext } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Container from "../container/container"
import Input from "../form/Input"
import { getUser } from "../../services/auth"
import { updateProfile, changePassword } from "../../services/profile"
import { AuthContext } from "../../context/AuthContext"
import Button from "../button/button"
import FormHeader from "../form/form-header"
import { toast } from "react-toastify"

const schema = yup
  .object({
    old_password: yup.string().required("Current Password is required"),
    new_password: yup.string().required("New Password is required"),
    confirm_password: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("new_password"), null], "Passwords must match"),
  })
  .required()

const ChangePasswordForm = ({ component: Component, location }) => {
  const { handleSyncUser } = useContext(AuthContext)

  const user = getUser()

  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    defaultValues,
    reset,
    formState: { errors },
  } = methods

  const [generalMsg, setGeneralMsg] = useState("")

  const onSubmit = async (data) => {
    // console.log("onSubmit: ", data)
    data.user = user

    changePassword(data).then((res) => {
      // console.log("changePassword() Response: ", res)
      if (res.status) {
        // console.log("onSubmit RES", data)
        toast.success(res.message)
        handleSyncUser()
        reset()
      } else if (res.message) {
        toast.error(res.message)
      }
    })
  }

  return (
    <div>
      {!!generalMsg && <p className="input--error">{generalMsg}</p>}
      <FormHeader
        border
        heading={"Password change"}
        text={"Feel free to update your password so your account stays secure."}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Input
            label={"Current Password"}
            name="old_password"
            type="password"
            {...register("old_password")}
            required
            placeholder={"Your Current Password"}
            errorMessage={errors.old_password?.message}
            secondary
          />
          <Input
            label={"New Password"}
            name="new_password"
            type="password"
            {...register("new_password")}
            required
            placeholder={"Your New Password"}
            errorMessage={errors.new_password?.message}
            secondary
          />
          <p>
            Password must be between 6-12 characters including one capital, one
            small letter and one number.
          </p>
          <Input
            label={"Confirm New Password"}
            name="confirm_password"
            type="password"
            {...register("confirm_password")}
            required
            placeholder={"Confirm Your New Password"}
            errorMessage={errors.confirm_password?.message}
            secondary
          />
          <Container pt={1}>
            <Button type="submit">Update Password</Button>
          </Container>
        </form>
      </FormProvider>
    </div>
  )
}
export default ChangePasswordForm
