import React from "react"
import style from "./profile-layout.mod.scss"
import UpdateProfileForm from "./update-profile-form"
import ChangePasswordForm from "./change-password-form"

const ProfileLayout = () => {
  return (
    <div className={style.layout}>
      <UpdateProfileForm />
      <ChangePasswordForm />
    </div>
  )
}

export default ProfileLayout
