import React, { useState, useEffect } from "react"
import style from "./address-list.mod.scss"
import AddressItem from "./address-item"
import { getUser } from "../../services/auth"
import { getDefaultAddress, getAddresses } from "../../services/addresses"

const AddressList = () => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)

  const [addresses, setAddresses] = useState([])
  const [defaultAddress, setDefaultAddress] = useState(false)

  const handleGetAddresses = () => {
    const user = getUser()
    const getAddressesData = {
      user: user,
    }
    getDefaultAddress(getAddressesData).then((res) => {
      if (res) {
        setDefaultAddress(res)
      } else {
        setDefaultAddress(false)
      }
    })
    // console.log("checking")
    getAddresses(getAddressesData).then((res) => {
      if (res.data.totalAddressCount > 0) {
        setAddresses(res.data.address)
      } else {
        setAddresses([])
        setError("You have no saved addresses")
      }
    })
  }

  useEffect(() => {
    handleGetAddresses()
  }, [succeeded, error])

  return (
    <div className={style.list}>
      <div className={style.list__grid}>
        {addresses.map((item) => (
          <AddressItem
            setError={setError}
            setSucceeded={setSucceeded}
            item={item}
          />
        ))}
      </div>
    </div>
  )
}

export default AddressList
