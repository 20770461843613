import React, { useState, useEffect } from "react"
import { getPaymentMethods } from "../../services/payment-methods"
import Container from "../container/container"
import { getUser } from "../../services/auth"
import PaymentMethodItem from "./payment-methods-item"
import style from "./payment-methods.mod.scss"
import AccountHeader from "../account/account-header"
import AddCardElement from "./add-card-element"

const PaymentMethods = () => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)

  const [paymentMethods, setPaymentMethods] = useState([])
  const [showPaymentList, setShowPaymentList] = useState(true)

  const handleGetPaymentMethods = () => {
    const user = getUser()
    const getPaymentMethodsData = {
      user: user,
    }
    getPaymentMethods(getPaymentMethodsData).then((res) => {
      // console.log("getPaymentMethods use() Response: ", res)

      if (res.data.cards) {
        setPaymentMethods(res.data.cards)
      } else {
        setPaymentMethods([])
        setError("You have no saved payment methods")
      }
    })
  }

  useEffect(() => {
    handleGetPaymentMethods()
  }, [succeeded, error, showPaymentList])

  return (
    <>
      <AccountHeader
        heading={"Your payment methods"}
        buttonText={showPaymentList ? "Add payment method" : "Cancel"}
        buttonMethod={() => setShowPaymentList(!showPaymentList)}
      />
      {showPaymentList ? (
        <div className={style.methods__list}>
          {paymentMethods.map((item) => (
            <PaymentMethodItem
              setError={setError}
              setSucceeded={setSucceeded}
              {...item}
            />
          ))}
        </div>
      ) : (
        <Container width={"small"}>
          <AddCardElement
            // cancelMethod={() => setShowPaymentList(false)}
            handlePaymentMethodSubmission={() => setShowPaymentList(true)}
          />
        </Container>
      )}
    </>
  )
}

export default PaymentMethods
