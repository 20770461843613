import React, { useState } from "react"
import AccountHeader from "../account/account-header"
import AddressList from "../addresses/address-list"
import AddAddressForm from "./add-address-form"

const Address = ({ component: Component, location, ...rest }) => {
  const [showAddressList, setShowAddressList] = useState(true)

  return (
    <div>
      <AccountHeader
        heading={"Your addresses"}
        buttonText={showAddressList ? "Add new address" : "Cancel"}
        buttonMethod={() => setShowAddressList(!showAddressList)}
      />
      {showAddressList ? (
        <AddressList />
      ) : (
        <AddAddressForm
          handleAddressFormSubmission={() => setShowAddressList(false)}
        />
      )}
    </div>
  )
}

export default Address
