import React from "react"
import style from "./account-layout.mod.scss"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import ProfileLayout from "../profile/profile-layout"
import Address from "../addresses/address"
import PaymentMethods from "../payment-methods/payment-methods"
import MyOrders from "../orders/orders"

const AccountLayout = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={style.layout}>
      <div className={style.layout__tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="profile-tabs"
          centered
        >
          <Tab label="Profile" />
          <Tab label="My Orders" />
          <Tab label="Payment Methods" />
          <Tab label="Addresses" />
        </Tabs>
      </div>
      <div>
        <TabPanel value={value} index={0}>
          <ProfileLayout />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MyOrders />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PaymentMethods />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Address />
        </TabPanel>
      </div>
    </div>
  )
}

export default AccountLayout

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
